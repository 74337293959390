import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  message,
  Row,
  Spin,
  Table,
  Typography,
} from "antd";
import "./responsive.css";
import "../App.css";
import Email_Status from './sendData.json'
import { Select } from "antd";
import { Column } from "@ant-design/plots";
import { PhoneOutlined } from "@ant-design/icons";
import { JsonSchemaForm } from "@antv/xflow-extension";
const { Option } = Select;
const { Title } = Typography;

export const ZOHO = window.ZOHO;

const EmailShedule = () => {
  // Date Filtering State
  const [selectedDate, setSelectedDate] = useState(7);
  const [seceletduser, setSelectUser] = useState([]);
  const [activeuser, setActiveUser] = useState([]);
  // All Task State
  const [sevenDaysTasks, setSevenDaysAllTasks] = useState([]);
  const [todaysTask, setTodaysTaskData] = useState([]);
  const [taskUpcomingData, setTaskUpcomingData] = useState([]);
  const [taskOverDueFilteringData, setTaskOverDueFilteringData] = useState([]);
  const [tasksOverdue, setTasksOverdue] = useState([]);
  // All Email Module State 
  const [emailTodaysUpcomingData, setToDaysEmailUpcomingData] = useState([]);
  const [todaysEmailData, setTodaysEmailData] = useState([]);
  // All Calls Module State 
  const [allCalls, setAllCalls] = useState([]);
  const [todaysCallData, setTodaysCallData] = useState([]);
  const [callOutBound, setCallOutBoundData] = useState([]);
  const [callComplate, setCallComplateData] = useState([]);
  const [callOverDue, setCallOverDueData] = useState([]);
  // Send Email 
  const [emailSendData, setEmailSendfData] = useState([])
  const [FullWeekData, setFullWeekData] = useState([]);
  // Acticity Point State 
  const [activityPoints, SetActivityPoints] = useState([]);
  const [allActivityPointsCal, SetallActivityPointsCal] = useState([]);
  const [allTodaysTask, setAllTodayTaskData] = useState([]);

  useEffect(() => {
    ZOHO.embeddedApp.on("PageLoad", function (data) {
      //  Task Function 
      getUpcomingApiTasks();
      getApiTodayTasks()
      getApiTasksOverDue();
      // Call Function 
      getApiTodaysCalls();
      getApiOthersCall()
      // Users 
      getAllUser();

      getActivityApiPoints();
      getApiEmailStatus();


    });

    ZOHO.embeddedApp.init();
  }, []);

  //Task Clumns Data Start 
  const taskDataCloumns = [
    {
      title: "Owner Name",
      dataIndex: "names",
      render: (text, record) => (
        <span> {record?.["Owner.first_name"] + " " + record?.["Owner.last_name"]} </span>
      )
    },
    {
      title: "Prospects Name",
      dataIndex: "Prospects_Name",
      key: "Prospects_Name",

    },
    {
      title: "Subject",
      dataIndex: "Subject",
      key: "Subject",
    },
    {
      title: "Task Type",
      dataIndex: "Task_Type",
      key: "Task_Type",
    },
    {
      title: "Task Status",
      dataIndex: "Status",
      key: "Status",
    },

    {
      title: "Due Date",
      dataIndex: "Due_Date",
      key: "Due_Date",
    },

    {
      title: "View Task Details ",
      key: "action",
      render: (text, record) => (
        <span>
          <a
            onClick={() => {
              viewTask(record);
            }}
          >
            <i className=" fas fa-solid fa-eye"></i> &nbsp; View Task
          </a>
        </span>
      ),
    },

  ];

  // out Bound Data Columns
  const callDataClumns = [
    {
      title: "Owner Name",
      dataIndex: "names",
      render: (text, record) => (
        <span> {record?.["Owner.first_name"] + " " + record?.["Owner.last_name"]} </span>
      )
    },

    {
      title: "Prospects Name",
      dataIndex: "Prospect_Name",
      key: "Prospect_Name",
      // render: (item) => (item ? Object.values(item)[0] : "Name Not Found !!"),
    },

    {
      title: "Call Type",
      dataIndex: "Call_Type",
      key: "Call_Type",
    },
    {
      title: "Call Status",
      dataIndex: "Call_Status",
      key: "Call_Status",
    },
    {
      title: "Prospect Status",
      dataIndex: "Calls_Status",
      key: "Calls_Status",
    },

    {
      title: "Call Result",
      dataIndex: "Call_Result",
      key: "Call_Result",
    },
    {
      title: "Subject",
      dataIndex: "Subject",
      key: "Subject",
    },

    {
      title: "Call Start Time ",
      dataIndex: "Call_Start_Time",
      key: "Call_Start_Time",
    },

    {
      title: "Call",
      key: "action",
      render: (text, record) => (
        <span>
          <a
            onClick={() => {
              callNow(record);
            }}
          >
            <PhoneOutlined /> Call Now{" "}
          </a>
        </span>
      ),
    },
  ];

  //Task Clumns Data End  
  const config = {
    data: FullWeekData,
    xField: "type",
    yField: "value",
    legend: true,
  };

  return (
    <>

      {console.log(Email_Status)}

      {/* Date and Username Filtering  */}
      <section style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ margin: "20px", marginBottom: 20 }}>
          <Select
            style={{ width: 250 }}
            placeholder="Please Select an Admin "
            onChange={UserSelectHandler}
          >
            {activeuser?.map((data) => {
              return <Option value={data.id}>{
                data?.["first_name"] + " " + data?.["last_name"]
              }</Option>;
            })}
          </Select>
        </div>

        <div style={{ margin: "20px", marginBottom: 20 }}>
          <Select
            style={{ width: 250 }}
            onChange={showReport}
            placeholder="Please Select an Option "
            defaultValue="7"
          >
            {/* <Option value="0">Today</Option> */}
            <Option value="7">Last 7 Days </Option>
            <Option value="15">Last 15 Days</Option>
            <Option value="30">Last 30 Days</Option>
          </Select>
        </div>

        <div style={{ margin: "20px", marginBottom: 20 }}>
          <Button onClick={showResult} type="primary">
            Show Result
          </Button>
        </div>
      </section>


      <section>
        <Row gutter={[16, 16]}>
          <Col
            xs={{ span: 24, offset: 0 }}
            lg={{ span: 8, offset: 0 }}
            md={{ span: 8, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
          >
            {/* Call Shedule  */}
            <Card
              style={{ height: 165 }}
              title={
                <Title
                  id="card_title"
                  style={{ textAlign: "left", fontSize: "20px" }}
                >
                  Calls Scheduled
                </Title>
              }
              extra={
                <span style={{ fontSize: "20px" }}>{callOutBound.length}</span>
              }
            >
              <Row>
                <Col
                  xs={{ span: 6, offset: 0 }}
                  lg={{ span: 6, offset: 0 }}
                  md={{ span: 6, offset: 0 }}
                  sm={{ span: 6, offset: 0 }}
                  style={{ borderRight: "2px solid #D1D5D8" }}
                >
                  <div> Today </div>
                  <div>
                    <strong
                      className="red"
                      tabindex="1"
                      style={{
                        cursor: "-webkit-grab",
                        cursor: "grab",
                        // color:'#3498db'
                      }}
                      onClick={TodayCallRecords}
                    >
                      {todaysCallData.length}
                    </strong>
                  </div>
                </Col>

                <Col
                  xs={{ span: 6, offset: 0 }}
                  lg={{ span: 6, offset: 0 }}
                  md={{ span: 6, offset: 0 }}
                  sm={{ span: 6, offset: 0 }}
                  style={{ borderRight: "2px solid #D1D5D8" }}
                >
                  <div> Overdue </div>
                  <div>
                    <strong
                      className="red"
                      tabindex="1"
                      style={{
                        cursor: "-webkit-grab",
                        cursor: "grab",
                        // color:'#3498db'
                      }}
                      onClick={overdueCallRecords}
                    >
                      {callOverDue.length}  </strong>
                  </div>
                </Col>

                <Col
                  xs={{ span: 6, offset: 0 }}
                  lg={{ span: 6, offset: 0 }}
                  md={{ span: 6, offset: 0 }}
                  sm={{ span: 6, offset: 0 }}
                  style={{ borderRight: "2px solid #D1D5D8" }}
                >
                  <div> Outbound </div>
                  <div>
                    <strong
                      className="red"
                      tabindex="1"
                      style={{
                        cursor: "-webkit-grab",
                        cursor: "grab",
                        // color:'#3498db'
                      }}
                      onClick={callOutBoundBtn}
                    >
                      {callOutBound.length}
                    </strong>
                  </div>
                </Col>

                <Col
                  xs={{ span: 6, offset: 0 }}
                  lg={{ span: 6, offset: 0 }}
                  md={{ span: 6, offset: 0 }}
                  sm={{ span: 6, offset: 0 }}
                >
                  <div>Completed Calls </div>
                  <div>
                    <strong
                      className="red"
                      tabindex="1"
                      style={{
                        cursor: "-webkit-grab",
                        cursor: "grab",
                        // color:'#3498db'
                      }}
                      onClick={callComplateBtn}
                    >
                      {callComplate.length}
                    </strong>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col
            xs={{ span: 24, offset: 0 }}
            lg={{ span: 8, offset: 0 }}
            md={{ span: 8, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
          >
            <Card
              style={{ height: 165 }}
              title={
                <Title
                  id="card_title"
                  style={{ textAlign: "left", fontSize: "20px" }}
                >
                  Tasks Scheduled
                </Title>
              }
              extra={
                <span style={{ fontSize: "20px" }}>
                </span>
              }
            >
              <Row>
                <Col
                  xs={{ span: 24, offset: 0 }}
                  lg={{ span: 8, offset: 0 }}
                  md={{ span: 8, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  style={{ borderRight: "2px solid #D1D5D8" }}
                >
                  <div>Today </div>
                  <div>
                    <strong
                      className="red"
                      tabindex="1"
                      onClick={todaysTaskDataBtn}
                      style={{
                        cursor: "-webkit-grab",
                        cursor: "grab",
                      }}
                    >
                      {todaysTask.length}
                    </strong>{" "}
                  </div>
                </Col>

                <Col
                  xs={{ span: 24, offset: 0 }}
                  lg={{ span: 8, offset: 0 }}
                  md={{ span: 8, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  style={{ borderRight: "2px solid #D1D5D8" }}
                >
                  <div>Upcoming </div>
                  <div>
                    {" "}
                    <strong
                      className="red"
                      tabindex="1"
                      onClick={taskUpcomingBtn}
                      style={{
                        cursor: "-webkit-grab",
                        cursor: "grab",
                        // color:'#3498db'
                      }}
                    >
                      {taskUpcomingData.length}
                    </strong>
                  </div>
                </Col>
                <Col
                  xs={{ span: 24, offset: 0 }}
                  lg={{ span: 8, offset: 0 }}
                  md={{ span: 8, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                >
                  <div>Overdue </div>
                  <div>
                    {" "}
                    <strong
                      style={{
                        cursor: "-webkit-grab",
                        cursor: "grab",
                        // color:'#3498db'
                      }}
                      className="red"
                      tabindex="1"
                      onClick={taskOverdueBtn}
                    >
                      {tasksOverdue.length}
                    </strong>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col
            xs={{ span: 24, offset: 0 }}
            lg={{ span: 8, offset: 0 }}
            md={{ span: 8, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
          >
            <Card
              style={{ height: 165 }}
              title={
                <Title
                  id="card_title"
                  style={{ textAlign: "left", fontSize: "20px" }}
                >
                  Emails Scheduled
                </Title>
              }
              extra={
                <span style={{ fontSize: "20px" }}>
                  {/* {prospectLogs.totalemail} */}
                </span>
              }
            >
              <Row>
                {/* Today Email Start  */}
                <Col
                  xs={{ span: 24, offset: 0 }}
                  lg={{ span: 12, offset: 0 }}
                  md={{ span: 12, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  style={{ borderRight: "2px solid #D1D5D8" }}
                >
                  <div>Today </div>
                  <div>
                    <strong
                      className="red"
                      tabindex="1"
                      style={{
                        cursor: "-webkit-grab",
                        cursor: "grab",
                        // color:'#010202'
                      }}
                      onClick={todayEmailBtnAction}
                    >
                      {" "}
                      {todaysEmailData.length}{" "}
                    </strong>
                  </div>
                </Col>

                {/* Today Email End  */}

                {/*Email Upcoming  Start */}
                <Col
                  xs={{ span: 24, offset: 0 }}
                  lg={{ span: 12, offset: 0 }}
                  md={{ span: 12, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                // style={{ borderRight: "2px solid #D1D5D8" }}
                >
                  <div>Upcoming Email </div>
                  <div>
                    <strong
                      className="red"
                      tabindex="1"
                      style={{
                        cursor: "-webkit-grab",
                        cursor: "grab",
                        // color:'#010202'
                      }}
                      onClick={emailUpcomingBtn}
                    >
                      {emailTodaysUpcomingData.length}
                    </strong>
                  </div>
                </Col>
                {/*Email Upcoming  End */}
              </Row>
            </Card>
          </Col>
        </Row>
      </section>
      <Row style={{ marginTop: 20 }} >
        <Col
          xs={{ span: 24, offset: 0 }}
          lg={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
        >
          {/* Task Table Start  */}

          <section >

            <div id="upTaskTable" style={{ backgroundColor: "white" }}>
              <h1 style={{ padding: 10, float: "left", marginLeft: 10 }}>
                Upcoming Task
              </h1>
              <Table
                style={{
                  padding: 15,
                }}
                columns={taskDataCloumns}
                dataSource={taskUpcomingData}
              />
            </div>
            <div
              id="overdueTaskTable11"
              style={{ backgroundColor: "white", display: "none" }}
            >
              <h1 style={{ padding: 10, float: "left", marginLeft: 10 }}>
                Overdue Task
              </h1>
              <Table
                style={{
                  padding: 15,
                }}
                columns={taskDataCloumns}
                dataSource={taskOverDueFilteringData}
              />
            </div>
            <div
              id="todaystaskData"
              style={{ backgroundColor: "white", display: "none" }}
            >
              <h1 style={{ padding: 10, float: "left", marginLeft: 10 }}>
                Todays Task
              </h1>
              <Table
                style={{
                  padding: 15,
                }}
                columns={taskDataCloumns}
                dataSource={todaysTask}
              />
            </div>
          </section>

          {/* Task Table End   */}
          {/* Email Table Start  */}
          <section>
            <div
              id="emailupcomingtabledata"
              style={{ backgroundColor: "white", display: "none" }}
            >
              <h1 style={{ padding: 10, float: "left", marginLeft: 10 }}>
                Upcoming Email
              </h1>
              <Table
                style={{
                  padding: 15,
                }}
                columns={taskDataCloumns}
                dataSource={emailTodaysUpcomingData}
              />
            </div>

            <div
              id="todayEmailData"
              style={{ backgroundColor: "white", display: "none" }}
            >
              <h1 style={{ padding: 10, float: "left", marginLeft: 10 }}>
                Todays Email
              </h1>
              <Table
                style={{
                  padding: 15,
                }}
                columns={taskDataCloumns}
                dataSource={todaysEmailData}
              />
            </div>


          </section>
          {/* Email Table End */}

          {/* Call Table Start */}
          <section>
            <div
              id="todaycallkData12"
              style={{ backgroundColor: "white", display: "none" }}
            >
              <h1 style={{ padding: 10, float: "left", marginLeft: 10 }}>
                Todays Data{" "}
              </h1>
              <Table
                style={{
                  padding: 15,
                }}
                columns={callDataClumns}
                dataSource={todaysCallData}
              />
            </div>


            <div
              id="callOverdue"
              style={{ backgroundColor: "white", display: "none" }}
            >
              <h1 style={{ padding: 10, float: "left", marginLeft: 10 }}>
                Over Due
              </h1>
              <Table
                style={{
                  padding: 15,
                }}
                columns={callDataClumns}
                dataSource={callOverDue}
              />
            </div>



            <div id="callOutBoundData" style={{ backgroundColor: "white" }}>
              <h1 style={{ padding: 10, float: "left", marginLeft: 10 }}>
                {" "}
                Outbound Calls
              </h1>
              <Table
                style={{
                  padding: 15,
                }}
                columns={callDataClumns}
                dataSource={callOutBound}
              />

            </div>

            <div id="complateCallsData" style={{ backgroundColor: "white" }}>
              <h1 style={{ padding: 10, float: "left", marginLeft: 10 }}>
                {" "}
                Complate Calls
              </h1>
              <Table
                style={{
                  padding: 15,
                }}
                columns={callDataClumns}
                dataSource={callComplate}
              />

            </div>
          </section>
          {/* Call TAble End */}

        </Col>

      </Row>


      <Row style={{ marginTop: 20 }} gutter={[16, 16]}>
        <Col
          xs={{ span: 24, offset: 0 }}
          lg={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
        >
          <div
            id="emailupcomingtabledata"
            style={{ backgroundColor: "white" }}
          >
            <div
              style={{
                padding: 40,
              }}
            >
              <Column {...config} />
              <h3
                style={{
                  //  float: "center",
                  marginTop: 15,
                  color: "gray",
                }}
              >
                Send Email Chart
              </h3>
            </div>
          </div>
        </Col>
      </Row>

      <div
        style={{
          marginTop: 15,
          backgroundColor: "white",

        }}
      >
        <h1
          style={{
            textAlign: "left",
            padding: "18px 10px",
          }}
        >
          Activity Points
        </h1>

        <table id="customers">
          <tr>
            <th> Owner Name</th>
            <th> 30 Days Point </th>
            <th> 7 Days Point</th>
            <th> Today Point</th>
          </tr>
          {allActivityPointsCal.map((item) => (
            <tr>
              <td>{item.name}</td>
              <td>{item.points}</td>
              <td>{item.sevendays}</td>
              <td>{item.today}</td>
            </tr>
          ))}
        </table>
      </div>
      <br>
      </br> <br>
      </br> <br>
      </br>
    </>
  );

  function isEmpty(a, b) {
    if (!b || a == b) {
      return true;
    }
  };

  function getDateFormet(add = 0, formet = "yyyy-mm-dd", start = true) {
    // yyyy-mm-ddThh:ii:ss-zm:zs
    // yyyy-mm-dd
    var date = new Date();
    date.setDate(date.getDate() + add);
    var dd = String(date.getDate()).padStart(2, "0");
    var mm = String(date.getMonth() + 1).padStart(2, "0");
    var yyyy = date.getFullYear();
    var zz = ("0" + date.getTimezoneOffset()).slice(-2);
    if (start) {
      var hh = "00";
      var ii = "00";
      var ss = "00";
    } else {
      var hh = "23";
      var ii = "59";
      var ss = "59";
    }
    var uu = ("0" + date.getMilliseconds()).slice(-2);
    var zpre = "+";
    if (zz < 0) {
      zpre = "-";
      zz = zz * -1;
    }
    var ztime = new Date(zz * 1000);
    let zm = zpre + ("0" + ztime.getMinutes()).slice(-2);
    let zs = ("0" + ztime.getSeconds()).slice(-2);

    formet = formet.replace("yyyy", yyyy);
    formet = formet.replace("mm", mm);
    formet = formet.replace("dd", dd);
    formet = formet.replace("hh", hh);
    formet = formet.replace("ii", ii);
    formet = formet.replace("ss", ss);
    formet = formet.replace("uu", uu);
    formet = formet.replace("-zm", zm);
    formet = formet.replace("zs", zs);
    return formet;
  }
  function getUpcomingApiTasks(
    fromDate = null,
    toDate = null,
    limit = 200,
    offset = 0,
    old = [],
    seleetedDate,
    seceletduser11
  ) {
    // Seven Task 
    var result = old;
    var to_day = getDateFormet(1 * selectedDate);
    var from_day = getDateFormet();
    var query =
      "select Owner,Prospects_Name, Owner.first_name,What_Id, Owner.last_name ,Subject,Created_By, Modified_By ,Task_Type,Description,Due_Date,Status,Task_Status,Created_Time from Tasks where ";
    if (seceletduser.length > 0) {
      query += "Owner = '" + seceletduser + "' and ";
    }
    query += "( Due_Date between '" +
      from_day +
      "' and '" +
      to_day +
      "') limit " +
      limit +
      " OFFSET " +
      offset;
    var config = {
      select_query: query,
    };

    ZOHO.CRM.API.coql(config).then(function (res) {
      if (res.data) {
        var newdata = result.concat(res.data);
        var taskUpcomingDataArray = [];
        var emailUpcomingDataForEmailModule = [];
        newdata.map((t) => {
          if (!t.Subject.includes("Email")) {
            if (t.Status !== "Completed") {
              taskUpcomingDataArray.push(t);
            }
          } else {
            if (t.Status !== "Completed") {
              emailUpcomingDataForEmailModule.push(t);
            }
          }
          // }
        })
        setTaskUpcomingData(taskUpcomingDataArray)
        setSevenDaysAllTasks(newdata);
        setToDaysEmailUpcomingData(emailUpcomingDataForEmailModule);
        if (res.info.more_records) {
          getUpcomingApiTasks(
            fromDate,
            toDate,
            limit,
            offset + limit,
            newdata,
            seleetedDate,
            seceletduser
          );
        }
      } else {
        setTaskUpcomingData([])
        setSevenDaysAllTasks([]);
        setToDaysEmailUpcomingData([]);
      }

    });
    // Upcoming End 


  }
  function getApiTodayTasks(
    fromDate = null,
    toDate = null,
    limit = 200,
    offset = 0,
    old = [],
    seleetedDate,
    seceletduser11
  ) {

    var toDaysTaskResult = old
    var to_day_for_one_day = getDateFormet(0);
    var from_day_for_oneday = getDateFormet(0);




    var query_for_today =
      "select Owner,Prospects_Name, Owner.first_name,What_Id, Owner.last_name ,Subject,Created_By, Modified_By ,Task_Type,Description,Due_Date,Status,Task_Status,Created_Time from Tasks where ";
    if (seceletduser.length > 0) {
      query_for_today += "Owner = '" + seceletduser + "' and ";
    }
    query_for_today += "( Due_Date between '" +
      to_day_for_one_day +
      "' and '" +
      from_day_for_oneday +
      "') limit " +
      limit +
      " OFFSET " +
      offset;




    var config_for_today = {
      select_query: query_for_today,
    };

    ZOHO.CRM.API.coql(config_for_today).then(function (res) {
      if (res.data) {
        var newTodaysData = toDaysTaskResult.concat(res.data);
        setAllTodayTaskData(newTodaysData);
        if (res.info.more_records) {
          getApiTodayTasks(
            fromDate,
            toDate,
            limit,
            offset + limit,
            newTodaysData,
            seceletduser
          );
        }
        else {
          to_days_task1(newTodaysData)
        }
      }
      else {
        to_days_task1([])
        setAllTodayTaskData([])
      }

    });

  }
  function getApiTasksOverDue(
    fromDate = null,
    toDate = null,
    limit = 200,
    offset = 0,
    old = [],
    seleetedDate,
    seceletduser11
  ) {
    // Seven Task 
    var result_due_data = old;
    var to_day_over_due = getDateFormet((-1) * selectedDate);
    var from_day_for_over_due = getDateFormet();
    var query_for_over_due =
      "select Owner,Prospects_Name, Owner.first_name,What_Id, Owner.last_name ,Subject,Created_By, Modified_By ,Task_Type,Description,Due_Date,Status,Task_Status,Created_Time from Tasks where ";
    if (seceletduser.length > 0) {
      query_for_over_due += "Owner = '" + seceletduser + "' and ";
    }
    query_for_over_due += "( Due_Date between '" +
      to_day_over_due +
      "' and '" +
      from_day_for_over_due +
      "') limit " +
      limit +
      " OFFSET " +
      offset;
    var config_for_over_due = {
      select_query: query_for_over_due,
    };
    ZOHO.CRM.API.coql(config_for_over_due).then(function (res) {
      if (res.data) {
        var newdata_for_overdue = result_due_data.concat(res.data);
        setTaskOverDueFilteringData(newdata_for_overdue)
        if (res.info.more_records) {
          getApiTasksOverDue(
            from_day_for_over_due,
            to_day_over_due,
            limit,
            offset + limit,
            newdata_for_overdue,
            seleetedDate,
            seceletduser
          );
        } else {
          TaskSeparationOverDue(newdata_for_overdue);
        }
      } else {

        setTaskOverDueFilteringData([]);
        TaskSeparationOverDue([]);
      }

    });
    // todays task dat
  }
  function getApiTodaysCalls(
    fromDate = null,
    toDate = null,
    limit = 200,
    offset = 0,
    old = [],
    seleetedDate,

  ) {

    // For Todays Call 
    var todaysCall = old;
    var to_day_for_today = getDateFormet(0, "yyyy-mm-ddThh:ii:ss-zm:zs", false);
    var from_day_for_today = getDateFormet(0, "yyyy-mm-ddThh:ii:ss-zm:zs");

    var to_day_query =
      "select Owner,Prospect_Name, Owner.first_name,What_Id,$se_module, Owner.last_name,Call_Type,Call_Status,Calls_Status,Call_Result,Call_Duration,Subject,Call_Start_Time from Calls where";
    if (seceletduser.length > 0) {
      to_day_query += " Owner = '" + seceletduser + "' and ";
    }
    to_day_query += "( Call_Start_Time between '" +
      from_day_for_today +
      "' and '" +
      to_day_for_today +
      "') limit " +
      limit +
      " OFFSET " +
      offset;




    var config_for_today = {
      select_query: to_day_query,
    };
    ZOHO.CRM.API.coql(config_for_today).then(function (res) {

      if (res.data) {
        var new_data_for_today = todaysCall.concat(res.data);
        setTodaysCallData(new_data_for_today);
        if (res.info.more_records) {
          getApiTodaysCalls(fromDate, toDate, limit, offset + limit, new_data_for_today, seleetedDate);
        }
      } else {
        setTodaysCallData([]);
      }
    });

  }
  function getApiOthersCall(
    fromDate = null,
    toDate = null,
    limit = 200,
    offset = 0,
    old = [],
    seleetedDate,

  ) {
    var result = old;
    var to_day = getDateFormet((-1) * selectedDate, "yyyy-mm-ddThh:ii:ss-zm:zs");
    var from_day = getDateFormet(0, "yyyy-mm-ddThh:ii:ss-zm:zs", false);
    var query =
      "select Owner,Prospect_Name, Owner.first_name,What_Id,$se_module, Owner.last_name,Call_Type,Call_Status,Calls_Status,Call_Result,Call_Duration,Subject,Call_Start_Time from Calls where";
    if (seceletduser.length > 0) {
      query += " Owner = '" + seceletduser + "' and ";
    }
    query += "( Call_Start_Time between '" +
      to_day +
      "' and '" +
      from_day +
      "') limit " +
      limit +
      " OFFSET " +
      offset;
    var config = {
      select_query: query,
    };
    ZOHO.CRM.API.coql(config).then(function (res) {
      if (res.data) {
        var newdata = result.concat(res.data);
        var overDuecallArray = [];
        var outBoundCallData = [];
        var complateCall = [];
        newdata.map((cd) => {
          if (cd.Call_Type == "Outbound") {
            outBoundCallData.push(cd);
          }
          if (cd.Call_Duration == null) {
            overDuecallArray.push(cd)
          }
          if (cd.Call_Duration >= "00:01") { complateCall.push(cd); }
        })
        setCallOverDueData(overDuecallArray);
        setCallOutBoundData(outBoundCallData);
        setCallComplateData(complateCall);
        setAllCalls(newdata);
        if (res.info.more_records) {
          getApiOthersCall(fromDate, toDate, limit, offset + limit, newdata, seleetedDate);
        }
      }
      else {
        setCallOverDueData([]);
        setCallOutBoundData([]);
        setCallComplateData([]);
        setAllCalls([]);

      }
    });

  }
  function getApiEmailStatus(
    fromDate = null,
    toDate = null,
    limit = 200,
    offset = 0,
    old = [],

  ) {
    var result = old;
    var to_day = getDateFormet(-7);
    var from_day = getDateFormet(0);



    var dateWiseData = {};
    Email_Status.map((s) => {
      dateWiseData[s.Email_Send_Date] = dateWiseData[s.Email_Send_Date] ? dateWiseData[s.Email_Send_Date] + 1 : 1;

    })
    //  TODO
    var fullWeekData = [];
    var d1 = new Date();
    var d2 = new Date();
    d2.setDate(d2.getDate() - 7);
    var td = new Date(d1.toLocaleDateString("en-US"));
    var fd = new Date(d2.toLocaleDateString("en-US"));

    const date = new Date(fd.getTime());
    while (date <= td) {
      var dt = new Date(date);
      var d = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
      var m =
        parseInt(dt.getMonth()) + 1 < 10
          ? "0" + (parseInt(dt.getMonth()) + 1)
          : parseInt(dt.getMonth()) + 1;

      // console.log("mmm" , m );
      var _date = dt.getFullYear() + "-" + m + "-" + d;

      //  console.log(_date);
      fullWeekData.push({
        type: _date,
        value: dateWiseData[_date] || 0,
      });
      date.setDate(date.getDate() + 1);
    }
    setFullWeekData(fullWeekData);

    setEmailSendfData(Email_Status);


  }
  function getActivityApiPoints(
    fromDate = null,
    toDate = null,
    limit = 200,
    offset = 0,
    old = []
  ) {
    var result = old;
    var to_day = getDateFormet(0, "yyyy-mm-dd");
    var from_day = getDateFormet(-30, "yyyy-mm-dd");
    var query =
      "select Created_Date,Owner.first_name, Owner.last_name , Points  from Activity_Points where Created_Date >= '" +
      from_day +
      "' AND Created_Date <= '" +
      to_day +
      "' limit " +
      limit +
      " OFFSET " +
      offset;
    var config = {
      select_query: query,
    };

    ZOHO.CRM.API.coql(config).then(function (res) {
      // setAllTasks.concat(res.data)
      var newdata = result.concat(res.data);
      // t?.["Owner.first_name"] + " " + t?.["Owner.last_name"]
      const strArr = JSON.stringify(newdata);
      const array = JSON.parse(strArr);
      var sum = {};
      for (var i = 0; i < array.length; i++) {
        var ele = array[i];
        if (!sum[ele?.["Owner.first_name"] + " " + ele?.["Owner.last_name"]]) {
          sum[ele?.["Owner.first_name"] + " " + ele?.["Owner.last_name"]] = {};
          sum[ele?.["Owner.first_name"] + " " + ele?.["Owner.last_name"]]["sum"] = 0;
          sum[ele?.["Owner.first_name"] + " " + ele?.["Owner.last_name"]]["sum7"] = 0;
          sum[ele?.["Owner.first_name"] + " " + ele?.["Owner.last_name"]]["sum1"] = 0;
        }
        if (between_date(ele.Created_Date, 60)) {
          sum[ele?.["Owner.first_name"] + " " + ele?.["Owner.last_name"]]["sum"] += ele.Points;
        }
        if (between_date(ele.Created_Date, 7)) {
          sum[ele?.["Owner.first_name"] + " " + ele?.["Owner.last_name"]]["sum7"] += ele.Points;
        }
        if (between_date(ele.Created_Date, 1)) {
          sum[ele?.["Owner.first_name"] + " " + ele?.["Owner.last_name"]]["sum1"] += ele.Points;
        }
      }
      var all_activity_point_result = []
      for (var name in sum) {
        all_activity_point_result.push({
          name: name,
          points: sum[name]["sum"],
          sevendays: sum[name]["sum7"],
          today: sum[name]["sum1"],
        });
      }
      SetallActivityPointsCal(all_activity_point_result)
      SetActivityPoints(newdata);
      if (res.info.more_records) {
        getActivityApiPoints(fromDate, toDate, limit, offset + limit, newdata);
      }
    });
  }
  function getAllUser() {
    ZOHO.CRM.API.getAllUsers({ Type: "AllUsers" }).then(function (data) {
      for (let i = 0; i < data.users.length; i++) {
        if (data.users[i].status === "active") {
          setActiveUser((preData) => [...preData, data.users[i]])
        }
      }
      // setActiveUser(data.users)
    });
  }
  function TaskSeparationOverDue(data = []) {
    var array = [];
    data.map((o) => {
      if (!o.Subject.includes("Email")) {
        if (o.Status !== "Completed") {
          array.push(o);
        }
      }
    })

    setTasksOverdue(array)
  }

  function to_days_task1(data = []) {
    var todaysTaskData = [];
    var todays_email_data = [];
    data.map((v) => {
      if (!v.Subject.includes("Email")) {
        if (v.Status !== "Completed") {
          todaysTaskData.push(v);
        }
      } else {
        if (v.Status !== "Completed") {
          todays_email_data.push(v);
        }
      }
    })
    setTodaysTaskData(todaysTaskData);
    setTodaysEmailData(todays_email_data)
  }





  function showResult() {
    getUpcomingApiTasks();
    getApiTodayTasks()
    getApiTasksOverDue();
    // Call Function 
    getApiTodaysCalls();
    getApiOthersCall()

  }
  function showReport(v) {
    setSelectedDate(v);
  }

  function UserSelectHandler(e) {
    setSelectUser(e);

  }
  // Table Fucntion Start
  var lastCalled = null;

  function callLastFunc(arg) {
    if (arg[0]) return;

    if (lastCalled) lastCalled("byCallPrev");
    lastCalled = arg.callee;
  }
  function viewTask(vt) {
    var id = "";
    try {
      id = vt.id;
    } catch (e) { }
    window.open(
      "https://crm.zoho.com/crm/org708576569/tab/Tasks/" + id,
      "_blank"
    );
  }
  function callNow(d) {
    var id = "";
    var w_id = "";
    var module_name = d.$se_module;
    // var module_name_contacts = "Accounts";


    try {
      id = d.What_Id.id;
      w_id = d.Who_Id.id;
    } catch (e) { }
    if (id) {
      window.open(
        "https://crm.zoho.com/crm/org708576569/tab/" + module_name + "/" + id,
        "_blank"
      );
    } else if (w_id) {
      window.open(
        "https://crm.zoho.com/crm/org708576569/tab/" + module_name + "/" + w_id,
        "_blank"
      );
    } else {
      message.warning("You can not Call This Number!!");
    }
  }
  // Task Showing Button Start 
  function taskOverdueBtn() {
    var overDueTask = document.getElementById("overdueTaskTable11").style;
    var upcomingTasktable = document.getElementById("upTaskTable").style;
    var email_upcoming_Data = document.getElementById("emailupcomingtabledata").style;
    var todaystaskData = document.getElementById("todaystaskData").style;
    var todayEmail = document.getElementById("todayEmailData").style;
    var todaycallkData12 = document.getElementById("todaycallkData12").style;
    var complateCall = document.getElementById("complateCallsData").style;
    var outBoundData = document.getElementById("callOutBoundData").style;
    var callOverDue = document.getElementById("callOverdue").style;


    if (!overDueTask.display | (overDueTask.display === "none")) {
      overDueTask.display = "block";
      todayEmail.display = "none";
      email_upcoming_Data.display = "none";
      upcomingTasktable.display = "none";
      todaystaskData.display = "none";
      todaycallkData12.display = "none";
      complateCall.display = "none";
      outBoundData.display = "none";
      callOverDue.display = "none";

    } else {
      overDueTask.display = "none";
    }
    callLastFunc(arguments);
  }
  function taskUpcomingBtn() {
    var e = document.getElementById("upTaskTable").style;
    var todaystaskData = document.getElementById("todaystaskData").style;
    var email_upcoming_Data = document.getElementById("emailupcomingtabledata").style;
    var todayEmail = document.getElementById("todayEmailData").style;
    var overDueTask = document.getElementById("overdueTaskTable11").style;
    var todaycallkData12 = document.getElementById("todaycallkData12").style;
    var outBoundData = document.getElementById("callOutBoundData").style;
    var complateCall = document.getElementById("complateCallsData").style;
    var callOverDue = document.getElementById("callOverdue").style;

    if (!e.display | (e.display == "none")) {
      e.display = "block";
      email_upcoming_Data.display = "none";
      todaycallkData12.display = "none";
      todayEmail.display = "none";
      todaystaskData.display = "none";
      overDueTask.display = "none";
      outBoundData.display = "none";
      complateCall.display = "none";
      callOverDue.display = "none";
    } else {
      e.display = "none";
    }
    callLastFunc(arguments);
  }
  function todaysTaskDataBtn() {
    var e = document.getElementById("todaystaskData").style;
    var upcomingTasktable = document.getElementById("upTaskTable").style;
    var email_upcoming_Data = document.getElementById(
      "emailupcomingtabledata"
    ).style;
    var todayEmail = document.getElementById("todayEmailData").style;
    var overDueTask = document.getElementById("overdueTaskTable11").style;
    var todaycallkData12 = document.getElementById("todaycallkData12").style;
    var outBoundData = document.getElementById("callOutBoundData").style;
    var complateCall = document.getElementById("complateCallsData").style;
    var callOverDue = document.getElementById("callOverdue").style;

    if (!e.display | (e.display == "none")) {
      e.display = "block";
      todayEmail.display = "none";
      email_upcoming_Data.display = "none";
      todaycallkData12.display = "none";
      overDueTask.display = "none";
      upcomingTasktable.display = "none";
      outBoundData.display = "none";
      complateCall.display = "none";
      callOverDue.display = "none";
    } else {
      e.display = "none";
    }
    callLastFunc(arguments);
  }
  // Task Showing button End 
  function emailUpcomingBtn() {
    var e = document.getElementById("emailupcomingtabledata").style;
    var todaystaskData = document.getElementById("todaystaskData").style;
    var overDueTask = document.getElementById("overdueTaskTable11").style;
    var upcomingTasktable = document.getElementById("upTaskTable").style;
    var todayEmail = document.getElementById("todayEmailData").style;
    var todaycallkData12 = document.getElementById("todaycallkData12").style;
    var callOverDue = document.getElementById("callOverdue").style;
    var outBoundData = document.getElementById("callOutBoundData").style;
    var complateCall = document.getElementById("complateCallsData").style;


    if (!e.display | (e.display == "none")) {
      e.display = "block";
      todayEmail.display = "none";
      todaystaskData.display = "none";
      upcomingTasktable.display = "none";
      todaycallkData12.display = "none";
      overDueTask.display = "none";
      callOverDue.display = "none";
      outBoundData.display = "none";
      complateCall.display = "none";
    } else {
      e.display = "none";
    }
    callLastFunc(arguments);
  }
  function todayEmailBtnAction() {
    var e = document.getElementById("todayEmailData").style;
    var todaystaskData = document.getElementById("todaystaskData").style;
    var overDueTask = document.getElementById("overdueTaskTable11").style;
    var upcomingTasktable = document.getElementById("upTaskTable").style;
    var email_upcoming_Data = document.getElementById(
      "emailupcomingtabledata"
    ).style;
    var todaycallkData12 = document.getElementById("todaycallkData12").style;
    var outBoundData = document.getElementById("callOutBoundData").style;
    var complateCall = document.getElementById("complateCallsData").style;
    var callOverDue = document.getElementById("callOverdue").style;

    if (!e.display | (e.display == "none")) {
      e.display = "block";
      todaystaskData.display = "none";
      overDueTask.display = "none";
      upcomingTasktable.display = "none";
      email_upcoming_Data.display = "none";
      todaycallkData12.display = "none";
      outBoundData.display = "none";
      complateCall.display = "none";
      callOverDue.display = "none";

    } else {
      e.display = "none";
    }
    callLastFunc(arguments);
  }
  // Email Button End 
  function TodayCallRecords() {
    var todaycallkData12 = document.getElementById("todaycallkData12").style;
    var todaystaskData = document.getElementById("todaystaskData").style;
    var overDueTask = document.getElementById("overdueTaskTable11").style;
    var upcomingTasktable = document.getElementById("upTaskTable").style;
    var email_upcoming_Data = document.getElementById(
      "emailupcomingtabledata"
    ).style;
    var todayEmail = document.getElementById("todayEmailData").style;
    var outBoundData = document.getElementById("callOutBoundData").style;
    var complateCall = document.getElementById("complateCallsData").style;
    var callOverDue = document.getElementById("callOverdue").style;
    if (!todaycallkData12.display | (todaycallkData12.display == "none")) {
      todaycallkData12.display = "block";
      todaystaskData.display = "none";
      overDueTask.display = "none";
      upcomingTasktable.display = "none";
      email_upcoming_Data.display = "none";
      todayEmail.display = "none";
      outBoundData.display = "none";
      complateCall.display = "none";
      callOverDue.display = "none";
    } else {
      todaycallkData12.display = "none";
    }
    callLastFunc(arguments);
  }

  function overdueCallRecords() {
    var e = document.getElementById("callOverdue").style;
    var todaycallkData12 = document.getElementById("todaycallkData12").style;
    var todaystaskData = document.getElementById("todaystaskData").style;
    var overDueTask = document.getElementById("overdueTaskTable11").style;
    var upcomingTasktable = document.getElementById("upTaskTable").style;
    var email_upcoming_Data = document.getElementById(
      "emailupcomingtabledata"
    ).style;
    var todayEmail = document.getElementById("todayEmailData").style;
    var complateCall = document.getElementById("complateCallsData").style;
    var outBoundData = document.getElementById("callOutBoundData").style;

    if (!e.display | (e.display == "none")) {
      e.display = "block";
      todaycallkData12.display = "none";
      overDueTask.display = "none";
      upcomingTasktable.display = "none";
      email_upcoming_Data.display = "none";
      todaystaskData.display = "none";
      complateCall.display = "none";
      todayEmail.display = "none";
      outBoundData.display = "none";
    } else {
      e.display = "none";

    }
    callLastFunc(arguments);
  }

  function callOutBoundBtn() {
    var e = document.getElementById("callOutBoundData").style;
    var callOverDue = document.getElementById("callOverdue").style;
    var todaycallkData12 = document.getElementById("todaycallkData12").style;
    var todaystaskData = document.getElementById("todaystaskData").style;
    var overDueTask = document.getElementById("overdueTaskTable11").style;
    var upcomingTasktable = document.getElementById("upTaskTable").style;
    var email_upcoming_Data = document.getElementById(
      "emailupcomingtabledata"
    ).style;
    var todayEmail = document.getElementById("todayEmailData").style;
    var complateCall = document.getElementById("complateCallsData").style;
    if (!e.display | (e.display == "none")) {
      e.display = "block";
      todaycallkData12.display = "none";
      overDueTask.display = "none";
      upcomingTasktable.display = "none";
      email_upcoming_Data.display = "none";
      todaystaskData.display = "none";
      complateCall.display = "none";
      todayEmail.display = "none";
      callOverDue.display = "none";
    } else {
      e.display = "none";

    }
    callLastFunc(arguments);
  }
  function callComplateBtn() {
    var e = document.getElementById("complateCallsData").style;
    var outBoundData = document.getElementById("callOutBoundData").style;
    var todaycallkData12 = document.getElementById("todaycallkData12").style;
    var todaystaskData = document.getElementById("todaystaskData").style;
    var overDueTask = document.getElementById("overdueTaskTable11").style;
    var upcomingTasktable = document.getElementById("upTaskTable").style;
    var email_upcoming_Data = document.getElementById(
      "emailupcomingtabledata"
    ).style;
    var todayEmail = document.getElementById("todayEmailData").style;
    var callOverDue = document.getElementById("callOverdue").style;

    if (!e.display | (e.display == "none")) {
      e.display = "block";
      todaycallkData12.display = "none";
      overDueTask.display = "none";
      upcomingTasktable.display = "none";
      email_upcoming_Data.display = "none";
      todaystaskData.display = "none";
      todayEmail.display = "none";
      outBoundData.display = "none";
      callOverDue.display = "none";
    } else {
      e.display = "none";
    }
    callLastFunc(arguments);
  }
  function between_date(date, before) {
    var cd = new Date();
    var bd = new Date();
    bd.setDate(bd.getDate() - parseInt(before));
    var d = new Date(date);
    const fd = new Date(cd.toLocaleDateString("en-US"));
    const td = new Date(bd.toLocaleDateString("en-US"));
    const dd = new Date(d.toLocaleDateString("en-US"));
    if (+dd <= +fd && +dd >= td) return true;
    return false;
  }
};
export default EmailShedule;
