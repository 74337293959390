import "./App.css";
import Home from "./Component/Home";

function App() {
  return (
    <div className="App">
      <Home style={{ height:800}}></Home>
    </div>
  );
}

export default App;
