import { Content } from "antd/lib/layout/layout";
import React from "react";
import Chart from "./Chart";
import EmailShedule from "./EmailShedule";
import "./responsive.css";

import { Row } from "antd";
const Home = () => {
  return (
    <div id="home" style={{ paddingTop:30}}>
      
      <section style={{ marginTop:30}}>
        <EmailShedule> </EmailShedule>
      </section>
    </div>
  );
};

export default Home;
